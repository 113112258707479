var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as d3 from 'd3';
import './Marker.scss';
import Utils from "../../Utils";
import { MARKERVALUENUMERICHEIGHT, LINECHARTXOFFSET } from "./../../Constants/Constants";
import { Component } from "./../../Interfaces/Component";
import Tooltip from '../Tooltip';
import { KeyCodes, ShiftTypes, DataTypes } from '../../Constants/Enums';
var MARKERSTRINGMAXLENGTH = 250;
var MARKERVALUEMAXWIDTH = 80;
var Marker = /** @class */ (function (_super) {
    __extends(Marker, _super);
    function Marker(renderTarget) {
        var _this = _super.call(this, renderTarget) || this;
        _this.tooltipMap = {};
        _this.labelText = '';
        _this.markerIsDragging = false;
        _this.isSeriesLabels = false;
        _this.ADDITIONALRIGHTSIDEOVERHANG = 12;
        _this.guid = Utils.guid();
        return _this;
    }
    Marker.prototype.getGuid = function () {
        return this.guid;
    };
    Marker.prototype.setMillis = function (millis) {
        this.timestampMillis = millis;
    };
    Marker.prototype.getMillis = function () {
        return this.timestampMillis;
    };
    // returns whether the string was trimmed to the max length
    Marker.prototype.setLabelText = function (labelText) {
        if (labelText.length > MARKERSTRINGMAXLENGTH) {
            this.labelText = labelText.slice(0, MARKERSTRINGMAXLENGTH);
            return true;
        }
        this.labelText = labelText;
        return false;
    };
    Marker.prototype.getLabelText = function () {
        return this.labelText;
    };
    Marker.prototype.setSeriesLabelText = function (d, text, isSeriesLabelInFocus) {
        text.classed('tsi-isExpanded', false);
        var title = text.append('h4')
            .attr('class', 'tsi-seriesLabelGroupName tsi-tooltipTitle');
        Utils.appendFormattedElementsFromString(title, d.aggregateName);
        var shiftTuple = this.chartComponentData.getTemporalShiftStringTuple(d.aggregateKey);
        var shiftString = '';
        if (shiftTuple !== null) {
            shiftString = shiftTuple[0] === ShiftTypes.startAt ? this.timeFormat(new Date(shiftTuple[1])) : shiftTuple[1];
        }
        var labelDatum = {
            splitBy: d.splitBy,
            variableAlias: this.chartComponentData.displayState[d.aggregateKey].aggregateExpression.variableAlias,
            timeShift: shiftString,
        };
        var subtitle = text.selectAll('.tsi-seriesLabelSeriesName').data([labelDatum]);
        var enteredSubtitle = subtitle.enter()
            .append('div')
            .attr('class', 'tsi-seriesLabelSeriesName tsi-tooltipSubtitle');
        if (labelDatum.splitBy && labelDatum.splitBy !== '') {
            enteredSubtitle.append('span')
                .classed('tsi-splitBy', true);
        }
        if (labelDatum.timeShift) {
            enteredSubtitle.append('span')
                .classed('tsi-timeShift', true);
        }
        if (labelDatum.variableAlias) {
            enteredSubtitle.append('span')
                .classed('tsi-variableAlias', true);
        }
        subtitle.exit().remove();
        Utils.setSeriesLabelSubtitleText(enteredSubtitle, false);
    };
    Marker.prototype.tooltipFormat = function (d, text, measureFormat, xyrMeasures, isSeriesLabelInFocus) {
        if (xyrMeasures === void 0) { xyrMeasures = null; }
        if (isSeriesLabelInFocus === void 0) { isSeriesLabelInFocus = false; }
        var tooltipHeight = MARKERVALUENUMERICHEIGHT;
        // revert to default text format if none specified
        if (!this.isSeriesLabels) {
            text.text(Utils.formatYAxisNumber(this.getValueOfVisible(d)))
                .style('height', tooltipHeight + 'px')
                .style('line-height', ((tooltipHeight - 2) + 'px')); // - 2 to account for border height
        }
        else {
            this.setSeriesLabelText(d, text, isSeriesLabelInFocus);
        }
        text.classed('tsi-markerValueTooltipInner', true)
            .style('border-color', this.colorMap[d.aggregateKey + "_" + d.splitBy]);
    };
    Marker.prototype.getLeft = function (d) {
        return Math.round(this.x(d.timestamp) + this.marginLeft);
    };
    // check to see if any marker is being dragged
    Marker.prototype.isMarkerDragOccuring = function () {
        return this.markerIsDragging;
        return (d3.select(this.renderTarget).selectAll('.tsi-markerContainer').filter(function (d) {
            return d.isDragging;
        }).size() > 0);
    };
    Marker.prototype.bumpMarker = function () {
        var _this = this;
        d3.select(this.renderTarget).selectAll('.tsi-markerContainer')
            .style('animation', 'none')
            .sort(function (a, b) {
            if (a.timestamp === _this.timestampMillis) {
                return 1;
            }
            if (b.timestamp === _this.timestampMillis) {
                return -1;
            }
            return a.timestamp < b.timestamp ? 1 : -1;
        });
    };
    Marker.prototype.renderMarker = function () {
        var _this = this;
        var self = this;
        var marker = d3.select(this.renderTarget).selectAll(".tsi-markerContainer")
            .filter(function (d) { return d.guid === _this.guid; })
            .data([{ guid: this.guid, timestamp: this.timestampMillis }]);
        this.markerContainer = marker.enter()
            .append('div')
            .attr('class', 'tsi-markerContainer')
            .classed('tsi-isSeriesLabels', this.isSeriesLabels)
            .merge(marker)
            .style('top', this.chartMargins.top + this.chartOptions.aggTopMargin + "px")
            .style('height', this.chartHeight - (this.chartMargins.top + this.chartMargins.bottom + this.chartOptions.aggTopMargin) + "px")
            .style('left', function (d) {
            return _this.getLeft(d) + "px";
        })
            .classed('tsi-isFlipped', function (d) {
            if (_this.isSeriesLabels) {
                return false;
            }
            return (_this.chartOptions.labelSeriesWithMarker && _this.x(d.timestamp) > (_this.x.range()[1] - MARKERVALUEMAXWIDTH));
        })
            .each(function (markerD) {
            if (self.isSeriesLabels) {
                return;
            }
            if (d3.select(this).selectAll('.tsi-markerLine').empty()) {
                d3.select(this).append('div')
                    .attr('class', 'tsi-markerLine');
                self.markerLabel = d3.select(this).append('div')
                    .attr('class', 'tsi-markerLabel')
                    .on('mouseleave', function () {
                    d3.select(this).classed('tsi-markerLabelHovered', false);
                });
                self.markerLabel.append('div')
                    .attr('class', 'tsi-markerGrabber')
                    .on('mouseenter', function () {
                    self.bumpMarker();
                });
                self.markerLabel.append('div')
                    .attr('class', 'tsi-markerLabelText')
                    .attr('contenteditable', 'true')
                    .text(self.labelText)
                    .on('keydown', function (event) {
                    if (event.keyCode === KeyCodes.Enter && !event.shiftKey) {
                        event.preventDefault();
                        self.closeButton.node().focus();
                    }
                })
                    .on('input', function () {
                    var didTrim = self.setLabelText(d3.select(this).text());
                    if (didTrim) {
                        d3.select(this).text(self.labelText);
                    }
                })
                    .on('focus', function () {
                    d3.select(this.parentNode).classed('tsi-markerLabelTextFocused', true);
                })
                    .on('blur', function () {
                    d3.select(this.parentNode).classed('tsi-markerLabelTextFocused', false);
                    self.onChange(false, false, false);
                })
                    .on('mousedown', function (event) {
                    event.stopPropagation();
                })
                    .on('mouseover', function () {
                    if (!self.isMarkerDragOccuring()) {
                        d3.select(d3.select(this).node().parentNode).classed('tsi-markerLabelHovered', true);
                        self.bumpMarker();
                    }
                });
                self.closeButton = self.markerLabel.append("button")
                    .attr("aria-label", self.getString('Delete marker'))
                    .classed("tsi-closeButton", true)
                    .on("click", function () {
                    self.onChange(true, false);
                    d3.select(d3.select(this).node().parentNode.parentNode).remove();
                });
                self.timeLabel = d3.select(this).append("div")
                    .attr('class', 'tsi-markerTimeLabel');
            }
            d3.select(this).selectAll('.tsi-markerTimeLabel,.tsi-markerLine,.tsi-markerLabel')
                .call(d3.drag()
                .on('start', function (event, d) {
                d.isDragging = true;
                self.markerIsDragging = true;
                self.bumpMarker();
            })
                .on('drag', function (event, d) {
                if (d3.select(event.sourceEvent.target).classed('tsi-closeButton')) {
                    return;
                }
                var marker = d3.select(d3.select(this).node().parentNode);
                var startPosition = self.x(new Date(self.timestampMillis));
                var newPosition = startPosition + event.x;
                self.timestampMillis = Utils.findClosestTime(self.x.invert(newPosition).valueOf(), self.chartComponentData.timeMap);
                self.setPositionsAndLabels(self.timestampMillis);
            })
                .on('end', function (event, d) {
                if (!d3.select(event.sourceEvent.target).classed('tsi-closeButton')) {
                    self.onChange(false, false);
                }
                d.isDragging = false;
                self.markerIsDragging = false;
            }));
        });
        marker.exit().remove();
    };
    Marker.prototype.getValueOfVisible = function (d) {
        return Utils.getValueOfVisible(d, this.chartComponentData.getVisibleMeasure(d.aggregateKey, d.splitBy));
    };
    Marker.prototype.getTooltipKey = function (d) {
        return d.aggregateKey + '_' + d.splitBy;
    };
    Marker.prototype.findYatX = function (x, path) {
        var pathParent = path.parentNode;
        var length_end = path.getTotalLength();
        var length_start = 0;
        var point = path.getPointAtLength((length_end + length_start) / 2);
        var bisection_iterations_max = 100;
        var bisection_iterations = 0;
        var error = 0.01;
        while (x < point.x - error || x > point.x + error) {
            point = path.getPointAtLength((length_end + length_start) / 2);
            if (x < point.x) {
                length_end = (length_start + length_end) / 2;
            }
            else {
                length_start = (length_start + length_end) / 2;
            }
            if (bisection_iterations_max < ++bisection_iterations) {
                break;
            }
        }
        var offset = path.parentNode.parentNode.transform.baseVal[0].matrix.f; // roundabout way of getting the y transform of the agg group
        return point.y + offset;
    };
    Marker.prototype.positionToValue = function (yPos, aggKey) {
        var yScale = this.yMap[aggKey];
        return yScale.invert(yPos);
    };
    Marker.prototype.bisectionInterpolateValue = function (millis, aggKey, splitBy, path) {
        if (path === null) {
            return null;
        }
        var yPosition = this.findYatX(this.x(millis), path);
        var interpolatedValue = this.positionToValue(yPosition, aggKey);
        var newDatum = this.createNewDatum(aggKey, splitBy, interpolatedValue);
        newDatum.isInterpolated = true;
        return newDatum;
    };
    Marker.prototype.getPath = function (aggKey, splitBy) {
        var selectedPaths = d3.select(this.renderTarget).selectAll('.tsi-valueLine').filter(function (d) {
            if (d.length) {
                return d[0].aggregateKey === aggKey && d[0].splitBy === splitBy;
            }
            return false;
        });
        if (selectedPaths.size() === 0) {
            return null;
        }
        return selectedPaths.nodes()[0];
    };
    Marker.prototype.createNewDatum = function (aggKey, splitBy, valueOfVisible) {
        var newDatum = {};
        newDatum.aggregateKey = aggKey;
        newDatum.splitBy = splitBy;
        newDatum.measures = {};
        newDatum.measures[this.chartComponentData.getVisibleMeasure(aggKey, splitBy)] = valueOfVisible;
        return newDatum;
    };
    Marker.prototype.findGapPath = function (aggKey, splitBy, millis) {
        var gapPath = d3.select(this.renderTarget).selectAll('.tsi-gapLine')
            .filter(function (d) {
            if (d.length === 2 && aggKey === d[0].aggregateKey && splitBy === d[0].splitBy) {
                return (millis > d[0].dateTime.valueOf() && millis < d[1].dateTime.valueOf());
            }
            return false;
        });
        if (gapPath.size() === 0) {
            return null;
        }
        return gapPath.nodes()[0];
    };
    //check if a value is within the time constrained bounds of a path
    Marker.prototype.inBounds = function (path, millis) {
        var _this = this;
        var filteredData = path.data()[0].filter(function (d) {
            return d.measures && _this.getValueOfVisible(d) !== null;
        });
        if (filteredData.length > 0) {
            var lowerBound = filteredData[0].dateTime.valueOf();
            var upperBound = filteredData[filteredData.length - 1].dateTime.valueOf();
            return millis >= lowerBound && millis <= upperBound;
        }
        return false;
    };
    Marker.prototype.getIntersectingPath = function (aggKey, splitBy, millis) {
        if (this.chartComponentData.displayState[aggKey].bucketSize) {
            millis = millis - (this.chartComponentData.displayState[aggKey].bucketSize / 2);
        }
        var gapPath = this.findGapPath(aggKey, splitBy, millis);
        if (gapPath) {
            return gapPath;
        }
        else {
            return this.inBounds(d3.select(this.getPath(aggKey, splitBy)), millis) ? this.getPath(aggKey, splitBy) : null;
        }
    };
    Marker.prototype.interpolateValue = function (millis, aggKey, splitBy) {
        var path = this.getIntersectingPath(aggKey, splitBy, millis);
        if (path === null) {
            return null;
        }
        return this.bisectionInterpolateValue(millis, aggKey, splitBy, path);
    };
    Marker.prototype.getValuesAtTime = function (closestTime) {
        var _this = this;
        var valueArray = [];
        var values = this.chartComponentData.timeMap[closestTime] != undefined ? this.chartComponentData.timeMap[closestTime] : [];
        Object.keys(this.chartComponentData.visibleTAs).forEach(function (aggKey) {
            Object.keys(_this.chartComponentData.visibleTAs[aggKey]).forEach(function (splitBy) {
                if (_this.chartComponentData.displayState[aggKey].dataType !== DataTypes.Numeric) {
                    return;
                }
                var filteredValues = values.filter(function (v) {
                    return (v.aggregateKey === aggKey && v.splitBy === splitBy && _this.getValueOfVisible(v) !== null);
                });
                if (filteredValues.length === 1 && (_this.getValueOfVisible(filteredValues[0]) !== null)) {
                    valueArray.push(filteredValues[0]);
                }
                else {
                    var interpolatedValue = _this.interpolateValue(closestTime, aggKey, splitBy);
                    if (interpolatedValue !== null || !_this.isSeriesLabels) {
                        valueArray.push(interpolatedValue);
                    }
                    else {
                        var lastValue = _this.chartComponentData.findLastTimestampWithValue(aggKey, splitBy);
                        if (lastValue !== null) {
                            valueArray.push(lastValue);
                        }
                    }
                }
            });
        });
        return valueArray;
    };
    Marker.prototype.setValueLabels = function (closestTime) {
        var _this = this;
        var values = this.getValuesAtTime(closestTime);
        values = values.filter(function (d) {
            return d && _this.chartComponentData.getDataType(d.aggregateKey) === DataTypes.Numeric;
        });
        var self = this;
        var valueLabels = this.markerContainer.selectAll(".tsi-markerValue").data(values, function (d) {
            return d.aggregateKey + "_" + d.splitBy;
        });
        valueLabels.enter()
            .append("div")
            .classed("tsi-markerValue", true)
            .classed('tsi-seriesLabelValue', this.isSeriesLabels)
            .merge(valueLabels)
            .classed('tsi-isInterpolated', function (d) {
            return d.isInterpolated;
        })
            .style('top', function (d) { return _this.calcTopOfValueLabel(d) + 'px'; })
            .each(function (d) {
            var tooltipKey = self.getTooltipKey(d);
            var tooltip;
            if (self.tooltipMap[tooltipKey]) {
                tooltip = self.tooltipMap[tooltipKey];
            }
            else {
                tooltip = new Tooltip(d3.select(this));
                self.tooltipMap[tooltipKey] = tooltip;
            }
            tooltip.render(self.chartOptions.theme);
            var tooltipHeight = MARKERVALUENUMERICHEIGHT;
            tooltip.draw(d, self.chartComponentData, 0, MARKERVALUENUMERICHEIGHT / 2, { right: 0, left: 0, top: 0, bottom: 0 }, function (tooltipTextElement) {
                self.tooltipFormat(d, tooltipTextElement, null, null);
            }, null, 0, 0, self.colorMap[d.aggregateKey + "_" + d.splitBy], true);
            var markerValueCaret = d3.select(this).selectAll('.tsi-markerValueCaret')
                .data([d]);
            markerValueCaret.enter().append('div')
                .attr('class', 'tsi-markerValueCaret')
                .merge(markerValueCaret)
                .style("border-right-color", function () { return self.colorMap[d.aggregateKey + "_" + d.splitBy]; });
            markerValueCaret.exit().remove();
        });
        var valueLabelsExit = valueLabels.exit();
        valueLabelsExit.each(function (d) {
            delete _this.tooltipMap[_this.getTooltipKey(d)];
        });
        valueLabelsExit.remove();
    };
    Marker.prototype.calcTopOfValueLabel = function (d) {
        var yScale = this.yMap[d.aggregateKey];
        return Math.round(yScale(this.getValueOfVisible(d)) - this.chartOptions.aggTopMargin);
    };
    Marker.prototype.getTimeFormat = function () {
        return Utils.timeFormat(this.chartComponentData.usesSeconds, this.chartComponentData.usesMillis, this.chartOptions.offset, this.chartOptions.is24HourTime, 0, null, this.chartOptions.dateLocale);
    };
    Marker.prototype.setTimeLabel = function (closestTime) {
        if (this.isSeriesLabels) {
            return;
        }
        var values = this.chartComponentData.timeMap[closestTime];
        if (values == undefined || values.length == 0) {
            return;
        }
        var firstValue = values[0].dateTime;
        var secondValue = new Date(values[0].dateTime.valueOf() + (values[0].bucketSize != null ? values[0].bucketSize : 0));
        this.timeLabel.text('');
        this.timeLabel.append('div')
            .attr('class', 'tsi-markerTimeLine')
            .text(this.timeFormat(firstValue));
        if (values[0].bucketSize !== null) {
            this.timeLabel.append('div')
                .attr('class', 'tsi-markerTimeLine')
                .text(this.timeFormat(secondValue));
        }
        var markerLeft = Number(this.markerContainer.style("left").replace("px", ""));
        var timeLabelWidth = Math.round(this.timeLabel.node().getBoundingClientRect().width);
        var minLeftPosition = this.marginLeft + LINECHARTXOFFSET;
        var width = this.x.range()[1] - this.x.range()[0];
        var maxRightPosition = width + this.marginLeft + LINECHARTXOFFSET + this.ADDITIONALRIGHTSIDEOVERHANG;
        var calculatedLeftPosition = markerLeft - (timeLabelWidth / 2);
        var calculatedRightPosition = markerLeft + (timeLabelWidth / 2);
        var translate = "translateX(calc(-50% + 1px))";
        if (calculatedLeftPosition < minLeftPosition) {
            translate = "translateX(-" + Math.max(0, markerLeft - minLeftPosition) + "px)";
        }
        if (calculatedRightPosition > maxRightPosition) {
            translate = "translateX(calc(-50% + " + (maxRightPosition - calculatedRightPosition) + "px))";
        }
        this.timeLabel
            .style("-webkit-tranform", translate)
            .style("transform", translate);
    };
    Marker.prototype.focusCloseButton = function () {
        if (this.closeButton) {
            this.closeButton.node().focus();
        }
    };
    Marker.prototype.isMarkerInRange = function (millis) {
        if (millis === void 0) { millis = this.timestampMillis; }
        var domain = this.x.domain();
        return !(millis < domain[0].valueOf() || millis > domain[1].valueOf());
    };
    Marker.prototype.destroyMarker = function () {
        if (this.markerContainer) {
            this.markerContainer.remove();
        }
        this.tooltipMap = {};
        this.markerContainer = null;
    };
    Marker.prototype.render = function (timestampMillis, chartOptions, chartComponentData, additionalMarkerFields) {
        this.chartMargins = Object.assign({}, additionalMarkerFields.chartMargins);
        this.chartHeight = additionalMarkerFields.chartHeight;
        this.timestampMillis = timestampMillis;
        this.chartOptions = chartOptions;
        this.x = additionalMarkerFields.x;
        this.chartComponentData = chartComponentData;
        this.marginLeft = additionalMarkerFields.marginLeft;
        this.colorMap = additionalMarkerFields.colorMap;
        this.timeFormat = this.getTimeFormat();
        this.isSeriesLabels = additionalMarkerFields.isSeriesLabels;
        if (additionalMarkerFields.labelText) {
            this.labelText = additionalMarkerFields.labelText;
        }
        this.yMap = additionalMarkerFields.yMap;
        if (additionalMarkerFields.onChange) { // only update onChange if passed in, otherwise maintain previous
            this.onChange = additionalMarkerFields.onChange;
        }
        if (!this.isMarkerInRange(this.timestampMillis)) {
            this.destroyMarker();
            return;
        }
        this.renderMarker();
        var closestTime = Utils.findClosestTime(this.timestampMillis, this.chartComponentData.timeMap);
        this.setPositionsAndLabels(closestTime);
        _super.prototype.themify.call(this, this.markerContainer, this.chartOptions.theme);
    };
    Marker.prototype.setPositionsAndLabels = function (millis) {
        if (!this.isMarkerInRange(millis)) {
            this.destroyMarker();
            return;
        }
        this.renderMarker();
        this.setTimeLabel(millis);
        this.setValueLabels(millis);
    };
    return Marker;
}(Component));
export default Marker;
