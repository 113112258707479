var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as d3 from 'd3';
import './Grid.scss';
import Utils from "../../Utils";
import { Component } from "./../../Interfaces/Component";
import { ChartComponentData } from '../../Models/ChartComponentData';
import { GRIDCONTAINERCLASS } from '../../Constants/Constants';
var Grid = /** @class */ (function (_super) {
    __extends(Grid, _super);
    function Grid(renderTarget) {
        var _this = _super.call(this, renderTarget) || this;
        _this.rowLabelKey = "__tsiLabel__";
        _this.colorKey = "__tsiColor__";
        _this.aggIndexKey = '__tsiAggIndex__';
        _this.chartComponentData = new ChartComponentData();
        _this.closeButton = null;
        _this.usesSeconds = false;
        _this.usesMillis = false;
        _this.cellClass = function (ridx, cidx) {
            return "tsi-table-" + ridx + '-' + cidx;
        };
        _this.focus = function (rowIdx, colIdx) {
            try {
                _this.gridComponent.select('.' + _this.cellClass(rowIdx, colIdx)).node()
                    .focus();
            }
            catch (e) {
                console.log(e);
            }
        };
        _this.getFormattedDate = function (h) {
            var hAsDate = (new Date(h));
            if (hAsDate != _this.getString('Invalid Date'))
                return Utils.timeFormat(_this.usesSeconds, _this.usesMillis, _this.chartOptions.offset, null, null, null, _this.chartOptions.dateLocale)(hAsDate);
            return h;
        };
        _this.setFilteredTimestamps = function () {
            if (_this.chartComponentData.fromMillis === Infinity) {
                _this.filteredTimestamps = _this.chartComponentData.allTimestampsArray;
            }
            else {
                _this.filteredTimestamps = _this.chartComponentData.allTimestampsArray.filter(function (ts) {
                    var currMillis = (new Date(ts)).valueOf();
                    return (currMillis >= _this.chartComponentData.fromMillis && currMillis < _this.chartComponentData.toMillis);
                });
            }
        };
        _this.arrowNavigate = function (d3event, rowIdx, colIdx) {
            if (d3event.keyCode === 9) {
                if (_this.closeButton) {
                    (_this.closeButton.node()).focus();
                    d3event.preventDefault();
                }
                return;
            }
            var codes = [37, 38, 39, 40];
            var codeIndex = codes.indexOf(d3event.keyCode);
            if (codeIndex == -1)
                return;
            switch (codeIndex) {
                case 0:
                    // left
                    _this.focus(rowIdx, colIdx - 1);
                    d3event.preventDefault();
                    break;
                case 1:
                    // up
                    _this.focus(rowIdx - 1, colIdx);
                    d3event.preventDefault();
                    break;
                case 2:
                    // right
                    _this.focus(rowIdx, colIdx + 1);
                    d3event.preventDefault();
                    break;
                case 3:
                    // down
                    _this.focus(rowIdx + 1, colIdx);
                    d3event.preventDefault();
                    break;
                default:
                    break;
            }
        };
        return _this;
    }
    Grid.hideGrid = function (renderTarget) {
        d3.select(renderTarget).selectAll("." + GRIDCONTAINERCLASS).remove();
    };
    Grid.showGrid = function (renderTarget, chartOptions, aggregateExpressionOptions, chartComponentData) {
        chartOptions.fromChart = true;
        d3.select(renderTarget).selectAll("." + GRIDCONTAINERCLASS).remove();
        var gridContainer = d3.select(renderTarget).append('div')
            .attr('class', GRIDCONTAINERCLASS)
            .style('width', '100%')
            .style('height', '100%');
        var gridComponent = new Grid(gridContainer.node());
        gridComponent.usesSeconds = chartComponentData.usesSeconds;
        gridComponent.usesMillis = chartComponentData.usesMillis;
        var grid = gridComponent.renderFromAggregates(chartComponentData.data, chartOptions, aggregateExpressionOptions, chartComponentData);
        gridComponent.focus(0, 0);
    };
    Grid.createGridEllipsisOption = function (renderTarget, chartOptions, aggregateExpressionOptions, chartComponentData, labelText) {
        var _this = this;
        if (labelText === void 0) { labelText = 'Display Grid'; }
        return {
            iconClass: "grid",
            label: labelText,
            action: function () {
                _this.showGrid(renderTarget, chartOptions, aggregateExpressionOptions, chartComponentData);
            },
            description: ""
        };
    };
    Grid.prototype.Grid = function () {
    };
    Grid.prototype.renderFromAggregates = function (data, options, aggregateExpressionOptions, chartComponentData) {
        var _this = this;
        this.chartOptions.setOptions(options);
        var dataAsJson = data.reduce(function (p, c, i) {
            var aeName = Object.keys(c)[0];
            Object.keys(c[aeName]).forEach(function (sbName) {
                var row = {};
                Object.keys(c[aeName][sbName]).forEach(function (dt) {
                    row[dt] = c[aeName][sbName][dt];
                });
                row[_this.rowLabelKey] = (Object.keys(c[aeName]).length == 1 && sbName == "" ? aeName : sbName);
                if (aggregateExpressionOptions && aggregateExpressionOptions[i].color)
                    row[_this.colorKey] = aggregateExpressionOptions[i].color;
                row[_this.aggIndexKey] = i;
                p.push(row);
            });
            return p;
        }, []);
        return this.render(dataAsJson, options, aggregateExpressionOptions, chartComponentData);
    };
    Grid.prototype.getRowData = function () {
        var _this = this;
        var rowData = [];
        Object.keys(this.chartComponentData.timeArrays).forEach(function (aggKey) {
            Object.keys(_this.chartComponentData.timeArrays[aggKey]).forEach(function (sb, sbI) {
                if (_this.chartComponentData.getSplitByVisible(aggKey, sb)) {
                    rowData.push([aggKey, sb]);
                }
            });
        });
        return rowData;
    };
    Grid.prototype.convertSeriesToGridData = function (allTimeStampMap, currSeries) {
        Object.keys(allTimeStampMap).forEach(function (k) { return allTimeStampMap[k] = {}; });
        currSeries = currSeries.filter(function (d) {
            return d.measures !== null;
        });
        currSeries.map(function (dataPoint) {
            allTimeStampMap[dataPoint.dateTime.toISOString()] = dataPoint;
        });
        return Object.keys(allTimeStampMap).map(function (ts) {
            return allTimeStampMap[ts];
        });
    };
    Grid.prototype.addHeaderCells = function () {
        var _this = this;
        var headerCellData = this.filteredTimestamps; // this.chartComponentData.allTimestampsArray;
        var headerCells = this.tableHeaderRow.selectAll('.tsi-headerCell').data(headerCellData);
        var headerCellsEntered = headerCells.enter()
            .append('th')
            .attr("tabindex", 1)
            .merge(headerCells)
            .attr("class", function (d, i) { return _this.cellClass(0, i + 1) + ' tsi-headerCell'; })
            .on("keydown", function (event, d) {
            var e = headerCellsEntered.nodes();
            var i = e.indexOf(event.currentTarget);
            _this.arrowNavigate(event, 0, i + 1);
        })
            .text(this.getFormattedDate)
            .attr('aria-label', function (h) {
            return _this.getString('column header for date') + " " + _this.getFormattedDate(h);
        });
        headerCellsEntered.exit().remove();
    };
    Grid.prototype.addValueCells = function () {
        var rowData = this.getRowData();
        var rows = this.table.selectAll('.tsi-gridContentRow').data(rowData);
        var self = this;
        var allTimeStampMap = this.filteredTimestamps.reduce(function (tsMap, ts) {
            tsMap[ts] = {};
            return tsMap;
        }, {});
        var headerCellData = this.filteredTimestamps;
        var rowsEntered = rows.enter()
            .append('tr')
            .classed('tsi-gridContentRow', true)
            .each(function (d, i) {
            var aggKey = d[0];
            var splitBy = d[1];
            var seriesData = self.convertSeriesToGridData(allTimeStampMap, self.chartComponentData.timeArrays[aggKey][splitBy]);
            var cells = d3.select(this).selectAll('.tsi-valueCell').data(seriesData);
            var measuresData = self.chartOptions.spMeasures ? self.chartOptions.spMeasures : self.chartComponentData.displayState[aggKey].splitBys[splitBy].types;
            //Row header with the name of the series
            var headerCell = d3.select(this).selectAll('tsi-rowHeaderCell').data([d]);
            var getRowHeaderText = function (d) {
                return "" + self.chartComponentData.displayState[aggKey].name + (splitBy !== '' ? (': ' + splitBy) : '');
            };
            headerCell.enter()
                .append('td')
                .attr("tabindex", 1)
                .merge(headerCell)
                .attr('class', function (d, col) { return "tsi-rowHeaderCell " + self.cellClass(i + 1, 0); })
                .on("keydown", function (event, d) {
                self.arrowNavigate(event, i + 1, 0);
            })
                .attr('aria-label', function (d) {
                return self.getString('row header for') + " " + Utils.stripNullGuid(getRowHeaderText(d));
            })
                .each(function (d) {
                d3.select(this).select('*').remove();
                var container = d3.select(this).append('div').attr('class', 'tsi-rowHeaderContainer');
                var seriesName = container.append('div')
                    .attr('class', 'tsi-rowHeaderSeriesName');
                Utils.appendFormattedElementsFromString(seriesName, getRowHeaderText(d));
                var measureContainer = container.append('div')
                    .attr('class', 'tsi-rowHeaderMeasures');
                var measureNames = measureContainer.selectAll('.tsi-measureName').data(measuresData);
                measureNames.enter()
                    .append('div')
                    .attr('class', 'tsi-measureName')
                    .text(function (d) { return d; });
            });
            headerCell.exit().remove();
            var cellsEntered = cells.enter()
                .append('td')
                .merge(cells)
                .attr('class', function (d, col) { return "tsi-valueCell " + self.cellClass(i + 1, col + 1); })
                .on("keydown", function (event, d) {
                var e = cellsEntered.nodes();
                var col = e.indexOf(event.currentTarget);
                self.arrowNavigate(event, i + 1, col + 1);
            })
                .attr("tabindex", 1)
                .attr('aria-label', function (d, i) {
                if (!d.measures || Object.keys(d.measures).length === 0) {
                    return self.getString('no values at') + " " + getRowHeaderText(d) + " and " + self.getFormattedDate(new Date(headerCellData[i]));
                }
                var formattedValues = Object.keys(d.measures).map(function (measureName) {
                    return measureName + ": " + d.measures[measureName];
                }).join(', ');
                return self.getString('values for cell at') + " " + getRowHeaderText(d) + " " + self.getString('and') + " " + self.getFormattedDate(d.dateTime) + " " + self.getString('are') + " " + formattedValues;
            })
                .each(function (d, i) {
                var measures = d3.select(this).selectAll('.tsi-measureValue').data(measuresData);
                measures.enter()
                    .append('div')
                    .attr('class', 'tsi-measureValue')
                    .text(function (measure) { return d.measures ? d.measures[measure] : ''; });
                measures.exit().remove();
            });
            cellsEntered.exit().remove();
        });
        rowsEntered.exit().remove();
    };
    Grid.prototype.render = function (data, options, aggregateExpressionOptions, chartComponentData) {
        var _this = this;
        if (chartComponentData === void 0) { chartComponentData = null; }
        data = Utils.standardizeTSStrings(data);
        this.chartOptions.setOptions(options);
        this.gridComponent = d3.select(this.renderTarget);
        if (chartComponentData) {
            this.chartComponentData = chartComponentData;
        }
        else {
            this.chartComponentData.mergeDataToDisplayStateAndTimeArrays(data, aggregateExpressionOptions);
        }
        this.setFilteredTimestamps();
        _super.prototype.themify.call(this, this.gridComponent, this.chartOptions.theme);
        this.gridComponent
            .classed("tsi-gridComponent", true)
            .classed("tsi-fromChart", !!options.fromChart);
        var grid = this.gridComponent
            .append('div')
            .attr("class", "tsi-gridWrapper")
            .attr("tabindex", 0)
            .on("click", function () {
            if (_this) {
                _this.focus(0, 0);
            }
        });
        var headers = Object.keys(data.reduce(function (p, c) {
            Object.keys(c).forEach(function (k) {
                if (k != _this.rowLabelKey && k != _this.colorKey)
                    p[k] = true;
            });
            return p;
        }, {})).sort();
        if (!this.table) {
            this.table = grid.append('table').classed('tsi-gridTable', true);
            this.tableHeaderRow = this.table.append('tr').classed('tsi-gridHeaderRow', true);
            this.tableHeaderRow.append('th')
                .attr("tabindex", 0)
                .attr("class", "tsi-topLeft " + this.cellClass(0, 0))
                .on("keydown", function (event) {
                _this.arrowNavigate(event, 0, 0);
            });
        }
        this.addHeaderCells();
        this.addValueCells();
        if (this.chartOptions.fromChart) {
            this.gridComponent.selectAll('.tsi-closeButton').remove();
            this.closeButton = grid.append('button')
                .attr("class", "tsi-closeButton")
                .attr('aria-label', this.getString('close grid'))
                .html('&times')
                .on('keydown', function (event) {
                if (event.keyCode === 9) {
                    _this.focus(0, 0);
                    event.preventDefault();
                }
            })
                .on("click", function () {
                if (!!options.fromChart) {
                    Utils.focusOnEllipsisButton(_this.renderTarget.parentNode);
                    _this.gridComponent.remove();
                }
            });
        }
    };
    return Grid;
}(Component));
export default Grid;
