var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as d3 from 'd3';
import './Hierarchy.scss';
import Utils from "../../Utils";
import { Component } from "./../../Interfaces/Component";
import { HierarchyNode } from "./../../Models/HierarchyNode";
var Hierarchy = /** @class */ (function (_super) {
    __extends(Hierarchy, _super);
    function Hierarchy(renderTarget) {
        var _this = _super.call(this, renderTarget) || this;
        _this.filterText = '';
        _this.withContextMenu = false;
        _this.expandCollapseList = function (node, el, isFromClick, event) {
            if (isFromClick === void 0) { isFromClick = false; }
            _this.closeContextMenu();
            if (el.classed('tsi-expanded') && !(_this.withContextMenu && node.isLeafParent)) {
                el.selectAll('ul').remove();
                el.classed('tsi-expanded', false);
                node.isExpanded = false;
            }
            else {
                if (_this.withContextMenu && node.isLeafParent) {
                    if (_this.clickedNode != el.node()) {
                        _this.clickedNode = el.node();
                        _this.contextMenu = _this.hierarchyList.append('div');
                        node.children.filter(function (n) { return n.name[0] !== '~'; }).forEach(function (n) {
                            _this.contextMenu.append('div').text("" + n.name).on('click', function () { return n.click(n); });
                        });
                        _this.contextMenu.append('div').classed('tsi-break', true);
                        node.children.filter(function (n) { return n.name[0] === '~'; }).forEach(function (n) {
                            var noTildeName = n.name.slice(1);
                            _this.contextMenu.append('div').text("" + noTildeName).on('click', function () { return n.click(n); });
                        });
                        _this.contextMenu.classed('tsi-hierarchyContextMenu', true);
                        if (event) {
                            var mouseWrapper_1 = d3.pointer(event, _this.hierarchyList.node());
                            var mouseElt_1 = d3.pointer(event, el.node());
                            _this.contextMenu.attr('style', function () { return "top: " + (mouseWrapper_1[1] - mouseElt_1[1]) + "px"; });
                        }
                        el.classed('tsi-resultSelected', true);
                        _this.hierarchyList.selectAll('.tsi-noPad').on('scroll', function () { _this.closeContextMenu(); });
                    }
                    else {
                        _this.clickedNode = null;
                    }
                }
                else {
                    var list = el.append('ul');
                    node.children.forEach(function (n) {
                        if (isFromClick || n.selfInFilter || n.childrenInFilter || (node.isExpanded && _this.filterText.length == 0)) {
                            var self = _this;
                            var clickMethod = function () {
                                if (n.isLeaf) {
                                    var parent = n.parent;
                                    while (parent != this.root) {
                                        parent.isExpanded = true;
                                        parent = parent.parent;
                                    }
                                    n.isSelected = !n.isSelected;
                                    n.click(n);
                                    var selector = d3.select(this);
                                    n.colorify(selector);
                                    selector.classed('tsi-selected', n.isSelected);
                                }
                                else {
                                    self.expandCollapseList(n, d3.select(this), true, event);
                                }
                                event.stopPropagation();
                            };
                            var li = list.append('li').classed('tsi-leaf', n.isLeaf)
                                .classed('tsi-leafParent', n.isLeafParent && _this.withContextMenu)
                                .classed('tsi-selected', n.isSelected).on('click', clickMethod);
                            li.append('span').classed('tsi-caret', true).attr('style', "left: " + (n.level - 1) * 18 + "px");
                            li.append('span').classed('tsi-markedName', true).html(n.markedName) // known unsafe usage of .html
                                .attr('style', "padding-left: " + (40 + (n.level - 1) * 18 - (n.isLeafParent && _this.withContextMenu ? 16 : 0)) + "px")
                                .attr('title', n.isLeafParent && _this.withContextMenu ? n.name : '');
                            n.colorify(li);
                            if ((n.isExpanded || n.childrenInFilter) && !n.isLeaf) {
                                _this.expandCollapseList(n, li, isFromClick, event);
                            }
                        }
                    });
                    node.isExpanded = (node.isExpanded || isFromClick) || (node == _this.root);
                    el.classed('tsi-expanded', true);
                }
            }
        };
        return _this;
    }
    Hierarchy.prototype.render = function (data, options) {
        var _this = this;
        var self = this;
        var targetElement = d3.select(this.renderTarget).classed('tsi-hierarchy', true);
        targetElement.html('');
        this.chartOptions.setOptions(options);
        _super.prototype.themify.call(this, targetElement, this.chartOptions.theme);
        this.withContextMenu = this.chartOptions.withContextMenu;
        this.root = this.buildTree(data);
        this.root.isExpanded = true;
        d3.select("html").on("click." + Utils.guid(), function (event) {
            if (_this.clickedNode && event.target != _this.clickedNode && _this.contextMenu) {
                _this.closeContextMenu();
                _this.clickedNode = null;
            }
        });
        var inputDebounceTimeout;
        var filter = targetElement.append('div').classed('tsi-filterWrapper', true).append('input').attr('placeholder', 'Search...').on('input', function (event) {
            var _this = this;
            clearTimeout(inputDebounceTimeout);
            inputDebounceTimeout = setTimeout(function () {
                self.filterText = _this.value.trim();
                if (self.filterText.length == 1)
                    return; // quick return for small sets
                var splitFilterText = self.filterText.split('/');
                self.root.filter(splitFilterText[0]);
                if (splitFilterText.length > 1) {
                    for (var i = 1; i < splitFilterText.length; i++) {
                        if (splitFilterText[i].length) {
                            var nodesInFilter = self.root.traverse(function (n) { return n.selfInFilter; });
                            nodesInFilter.forEach(function (n) {
                                var markedName = n.markedName;
                                n.filter(splitFilterText[i], false);
                                n.markedName = markedName;
                            });
                            nodesInFilter.forEach(function (n) {
                                if (!n.childrenInFilter)
                                    n.selfInFilter = false;
                            });
                        }
                    }
                }
                list.selectAll('ul').remove();
                list.classed('tsi-expanded', false);
                self.root.childrenInFilter = self.root.childrenInFilter || self.filterText.length == 0;
                if (self.root.childrenInFilter == false)
                    list.append('ul').append('div').text(self.getString('No filter results'));
                else
                    self.expandCollapseList(self.root, list, false, event);
                list.select('ul').classed('tsi-noPad', true);
            }, 250);
        });
        var navTabs = targetElement.append('div').classed('tsi-navTabWrapper', true);
        var allTab = navTabs.append('div').classed('tsi-navTab tsi-selected', true).text(this.getString('All hierarchies'));
        var selectedTab = navTabs.append('div').classed('tsi-navTab', true).text(this.getString('Selected'));
        var list = targetElement.append('div').classed('tsi-hierarchyList', true);
        this.hierarchyList = list;
        allTab.on('click', function (event) {
            if (!allTab.classed('tsi-selected')) {
                allTab.classed('tsi-selected', true);
                selectedTab.classed('tsi-selected', false);
                list.html('').classed('tsi-expanded', false);
                _this.expandCollapseList(_this.root, list, true, event);
                list.select('ul').classed('tsi-noPad', true);
                filter.attr('disabled', null);
            }
        });
        selectedTab.on('click', function () {
            if (!selectedTab.classed('tsi-selected')) {
                allTab.classed('tsi-selected', false);
                selectedTab.classed('tsi-selected', true);
                list.html('');
                var ul = list.append('ul').classed('tsi-noPad', true);
                var leafs = _this.root.traverse(function (n) { return n.isSelected; });
                leafs.forEach(function (n) {
                    var li = ul.append('li').classed('tsi-leaf', n.isLeaf).classed('tsi-selected', n.isSelected).on('click', function () {
                        n.isSelected = !n.isSelected;
                        d3.select(this).classed('tsi-selected', n.isSelected);
                        n.click(n);
                        n.colorify(d3.select(this));
                    });
                    li.append('span').text(n.name).classed('tsi-markedName', true);
                    n.colorify(li);
                });
                filter.attr('disabled', true);
            }
        });
        this.expandCollapseList(this.root, list, false);
        list.select('ul').classed('tsi-noPad', true);
    };
    Hierarchy.prototype.buildTree = function (data) {
        var traverse = function (data, key, level, parent) {
            if (parent === void 0) { parent = null; }
            var node = new HierarchyNode(key, level);
            node.parent = parent;
            if (data.hasOwnProperty('$leaf')) {
                node.isLeaf = true;
                if (data.hasOwnProperty('click'))
                    node.click = data.click;
                if (data.hasOwnProperty('color'))
                    node.color = data.color;
                node.parent.isLeafParent = true;
            }
            else {
                Object.keys(data).sort().forEach(function (k) {
                    node.children.push(traverse(data[k], k, level + 1, node));
                });
            }
            return node;
        };
        return traverse(data, '', 0);
    };
    Hierarchy.prototype.closeContextMenu = function () {
        if (this.contextMenu) {
            this.contextMenu.remove();
        }
        d3.selectAll('.tsi-resultSelected').classed('tsi-resultSelected', false);
    };
    return Hierarchy;
}(Component));
export default Hierarchy;
